var app;
(function (app) {
    var data;
    (function (data) {
        "use strict";
        var DataService = /** @class */ (function () {
            /* @ngInject */
            DataService.$inject = ["$timeout", "$http", "$q", "$rootScope", "$sce", "mylocalStore"];
            function DataService($timeout, $http, $q, $rootScope, $sce, mylocalStore) {
                this.$timeout = $timeout;
                this.$http = $http;
                this.$q = $q;
                this.$rootScope = $rootScope;
                this.$sce = $sce;
                this.mylocalStore = mylocalStore;
                /// clear all local data
                //mylocalStore.clearAll();
            }
            DataService.prototype.cleanLocal = function () {
                this.mylocalStore.clearAll();
            };
            DataService.prototype.jobsDATA = function (jobsDATA) {
                //   var has_local =   this.mylocalStore.myGetItem(jobsDATA);
                var _this = this;
                if (jobsDATA === void 0) { jobsDATA = "jobsDATA"; }
                //   if (has_local.exists){
                //     return has_local.p.then((localData)=>{
                //     //  console.log('-- getting jobsDATA local data',localData);
                //       return localData;
                //     })
                // }
                var Chilo_id = "163032";
                var url = "https://www.workable.com/api/accounts/" + Chilo_id;
                var trustedUrl = this.$sce.trustAsResourceUrl(url);
                return this.$http({
                    url: trustedUrl,
                    method: "JSONP",
                    data: {},
                    params: {
                        jsonpCallbackParam: "whrcallback",
                        origin: "embed",
                        _1519718716354: "",
                    },
                    responseType: "jsonp",
                    headers: { "Content-Type": "application/json" },
                }).then(function (response) {
                    // console.log()
                    if (response.data) {
                        if (response.data.jobs == undefined) {
                            return Promise.reject(_this.fail("no jobs data awailable"));
                        }
                    }
                    _this.mylocalStore.mySetItem(jobsDATA, _this.success(response)); //getItem
                    return _this.success(response);
                }, function (err) {
                    console.log("jobdata err", err);
                    return err;
                });
            };
            DataService.prototype.getDATA = function (lang) {
                // var has_local =   this.mylocalStore.myGetItem(lang);
                var _this = this;
                if (lang === void 0) { lang = "ENG"; }
                // if (has_local.exists){
                //     return has_local.p.then((localData)=>{
                //    //   console.log('-- getting local data')
                //       return localData;
                //     })
                // }
                var url = "./dist/js/locale-" + lang + ".json";
                var trustedUrl = this.$sce.trustAsResourceUrl(url);
                return this.$http({
                    url: trustedUrl,
                    method: "GET",
                    data: {},
                    headers: { "Content-Type": "application/json" },
                }).then(function (response) {
                    var lang = response.data.LANG;
                    //  if (lang ==='ENG'){ //myGetItem mySetItem
                    //  } if (lang ==='TH'){
                    //  }
                    if (_.isObject(response.data)) {
                        if (response.data.PAGES !== undefined) {
                            _this.$timeout(function () {
                                _this.mylocalStore.mySetItem(lang, _this.success(response)); //getItem
                            }, 100);
                        }
                    }
                    // console.log('json response', response);
                    return _this.success(response);
                }, function (err) {
                    return _this.fail(err, "server error");
                });
            };
            /**
             
             */
            DataService.prototype.success = function (response) {
                return { response: response.data, success: true };
            };
            DataService.prototype.fail = function (error, msg) {
                if (msg === void 0) { msg = ""; }
                if (!msg)
                    msg = "server error";
                return { error: error, message: msg, success: false };
            };
            return DataService;
        }());
        data.DataService = DataService;
        angular.module("app.dataservice", []);
        angular
            .module("app.dataservice")
            .service("dataservice", DataService);
    })(data = app.data || (app.data = {}));
})(app || (app = {}));
